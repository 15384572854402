/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'InterVariable';
    src: local('InterVariable'),
      url(assets/fonts/InterVariable.woff2) format('woff');
  }
}

/* Mac OS hides scrollbar by default on touchpad unless we connect external mouse.
We use this class to force scrollbar to be visible on touchpad.
*/
.visible-scrollbar *::-webkit-scrollbar {
  -webkit-appearance: none;
}

.visible-scrollbar *::-webkit-scrollbar:vertical {
  width: 7px;
  padding-right: 7px;
}

.visible-scrollbar *::-webkit-scrollbar:horizontal {
  height: 7px;
  padding-bottom: 7px;
}

.visible-scrollbar *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.inverted-scrollbar *::-webkit-scrollbar {
  -webkit-appearance: none;
}

.inverted-scrollbar *::-webkit-scrollbar:vertical {
  width: 7px;
  padding-right: 7px;
}

.inverted-scrollbar *::-webkit-scrollbar:horizontal {
  height: 7px;
  padding-bottom: 7px;
}

.inverted-scrollbar *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(184, 191, 204, 1);
}

.inverted-scrollbar *::-webkit-scrollbar-thumb:hover {
  background-color: rgba(184, 191, 204, 0.5);
}

@layer utilities {
  .animated-box-pulse {
    @apply bg-slate-200 rounded-md animate-pulse;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

.__argos__ {
  iframe,
  .intercom-lightweight-app {
    display: none;
  }
}

/* Hide default calendar icon in date input */
.custom-text-input::-webkit-inner-spin-button,
.custom-text-input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.flatfile-close-button {
  margin: 0px !important;
}
.flatfile_primary {
  background-color: black !important;
  border-radius: 6px !important;
}

.flatfile_displayAsModal {
  padding: 65px !important;
  border-radius: 8px !important;
  width: calc(100%) !important;
  height: calc(100vh) !important;
}

.flatfile_iFrameContainer {
  border-radius: 8px !important;
}

.agent-gradient-text {
  background: linear-gradient(to right, #b48bfa, #195ff5);
  background-size: 100% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chatbot-loading-text {
  background: linear-gradient(
    112deg,
    var(--colors-violet-400, #b48bfa) 9.13%,
    var(--colors-violet-200, #e5d6fe) 49.49%,
    var(--colors-violet-400, #b48bfa) 89.85%
  );
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 2s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
